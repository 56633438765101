.catalog {
  padding: 0 15px;
  &__container {
    margin-bottom: 80px;
  }
  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    width: 100%;
    text-align: center;
    margin-bottom: 45px;
    &-app {
      margin: none !important;
    }
  }
  .catalog__item1 {grid-area: one}
  .catalog__item2 {grid-area: two}
  .catalog__item3 {grid-area: three}
  .catalog__item4 {grid-area: four}
  .catalog__item5 {grid-area: fife}
  .catalog__item6 {grid-area: six}
  .catalog__item7 {grid-area: seven}
  .catalog__item8 {grid-area: "8"}
  .catalog__item9 {grid-area: "9"}

  &__items {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(3 , 370px);
    grid-template-areas:
      "one two four"
      "one three four"
      "fife six seven"
      "fife 8 9"
  }
  &__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 5px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    background: #FBF3EA;
    padding: 20px;
    max-width: 370px;
    &-apps {
      display: flex;
      gap: 15px;
      &-image {
        width: 150.7px;
        height: 45px;
      }
      &-link {

      }
    }
  }
  &__item_big {
    height: 540px;
  }
  &__item-top {
  }
  &__item-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #12B6FF;
    &-app {
      width: 100%;
      text-align: center;
      margin-bottom: 40px;
    }
  }
  &__item-bottom {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    &-app {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      gap: 20px;
      flex-direction: column;
    }
  }
  &__item-price {
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    color: #12B6FF;
  }
  &__item-image {
    &-app {
      width: 300px;
      height: 330px;
    }
  }
  &__item_small {
    height: 255px;
  }
}
@media (max-width: 1260px) {
  .catalog {
    &__items {
      width: 770px;
      margin: 0 auto;
      grid-template-columns: repeat(2 , 370px);
      grid-template-areas:
      "one four"
      "one four"
      "fife two"
      "fife three"
      "six seven"
      "8 9";
    }
  }
}
@media (max-width: 850px) {
  .catalog {
    &__items {
      width: 370px;
      grid-template-columns: repeat(1 , 370px);
      grid-template-areas:
      "four"
      "four"
      "one"
      "one"
      "fife"
      "fife"
      "two"
      "three"
      "six"
      "seven"
      "8"
      "9";
    }
    
    &__item_small {
      .catalog__item-image {
        width: auto;
        height: 80%;
      }
    }
  }
}

@media (max-width: 690px) {
  .catalog {
    &__title {
      font-size: 22px;
    }
  }
}
@media (max-width: 550px) {
  .catalog {
    &__items {
      width: 330px;
      grid-template-columns: repeat(1 , 330px);
    }
    &__item {
      &-apps {
        display: flex;
        flex-direction: column;
        gap: 15px;
      }
    }
    
    &__item-bottom {
      &-app {
        gap: 15px;
      }
    }
    &__item-title {
      font-size: 18px;
    }
    &__item-image {
      &-app {
        width: 270px;
        height: 300px;
      }
    }
    &__item_big {
      .catalog__item-image {
        width: 60%;
        height: auto;
      }
    }
    &__item_small {
      .catalog__item-image {
        width: auto;
        height: 80%;
      }
    }
    
  }
}
@media (max-width: 390px) {
  .catalog {
    &__items {
      width: 280px;
      grid-template-columns: repeat(1 , 280px);
      grid-template-areas:
      "four"
      "one"
      "fife"
      "two"
      "three"
      "six"
      "seven"
      "8"
      "9";
    }
    &__item {
      &-apps {
        display: flex;
        flex-direction: column;
        gap: 15px;
      }
    }
    &__item-bottom {
      &-app {
        gap: 15px;
      }
    }
    &__item-title {
      font-size: 16px;
    }
    &__item-price {
      font-size: 18px;
    }
    &__item-image {
      &-app {
        width: 230px;
        height: 260px;
      }
    }
    &__item_big {
      height: 460px;
      .catalog__item-image {
        width: 60%;
        height: auto;
      }
      &-app {
        height: 540px;
      }
    }
    &__item_small {
      .catalog__item-image {
        width: auto;
        height: 80%;
      }
    }
  }
}