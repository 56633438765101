.footer {
  padding: 0 15px;
  background: #3EAFC7;
  &__container {
    padding: 18px 0;
  }
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__logo {
    &-block {
    }
    &-link {
      display: flex;
      align-items: center;
    }
    &-img {
      width: 50px;
      height: 50px;
      margin-right: 10px;
    }
    &-name {
      font-weight: 700;
      font-size: 18px;
      line-height: 100%;
      color: #ffffff;
      margin-bottom: 5px;
    }
    &-number {
      font-family: $primary-ff;
      font-weight: 400;
      font-size: 14px;
      line-height: 100%;
      color: #ffffff
    }
  }
  &__policy {
    &-link {
      color: #ffffff;  
      line-height: 100%;
    }
  }
  &__maps {
    display: flex;
    align-items: center;
    gap: 10px;
    &-link {}
    &-img {
      width: 37px;
      height: 37px;
    }
  }
  &__socials {
    display: flex;
    align-items: center;
    column-gap: 5px;
    &-link {}
    &-img {
      width: 48px;
      height: 48px;
      &_small {
        width: 43px;
        height: 43px;
      }
    }
  }
  &__apps {
    display: flex;
    gap: 10px;
    &-link {}
    &-img {
      width: 134px;
      height: 40px;
    }
  }
}

.privacy {
  margin: 0 25px 0 25px;
  &__top {
    margin: 10px 0 25px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__top a {
    font-weight: 300;
    font-size: 24px;
    text-transform: uppercase;
    border-bottom: 1px solid #000;
    margin-left: 5px;
  }
  &__top a:hover {
    border-bottom: 0;
    margin-bottom: 1px;
  }
  &__title {
    text-transform: uppercase !important;
    font-weight: 600 !important;
    &_h3 {
      text-transform: uppercase !important;
      font-weight: 600 !important;
    }
  }
  &__ul p {
    font-size: 14px;
    margin: 5px 0 5px 15px;
    line-height: 20px;
  }
  &__ul li {
    font-size: 14px;
    margin: 5px 0 5px 15px;
    line-height: 20px;
  }
  &__ul-in-ul p {
    font-size: 14px;
    margin: 5px 0 5px 15px;
    line-height: 20px;
  }
  &__ul-in-ul li {
    font-size: 14px;
    margin: 5px 0 5px 25px;
    line-height: 20px;
  }
}

@media (max-width: 1050px) {
  .footer {
    &__top {
    }
    &__maps {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    &__apps {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }
}
@media (max-width: 860px) {
  .footer {
    &__policy {
      &-link {
        font-size: 14px;
      }
    }
    &__logo {
      &-img {
        width: 45px;
        height: 45px;
        margin-right: 10px;
      }
      &-name {
        font-size: 16px;
      }
      &-number {
        font-size: 12px;
      }
    }
  }
}
@media (max-width: 790px) {
  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    &__top {
      justify-content: flex-start;
      flex-direction: column;
      gap: 20px;
    }
    &__maps {
      flex-direction: row;
    }
    &__apps {
      flex-direction: row;
    }
  }
}

@media (max-width: 350px) {
  .footer {
    &__policy {
      &-link {
        font-size: 13px;
      }
    }
  }
}