.water-info {
  background: #3EAFC7;
  margin-bottom: 75px;
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 35px 15px;
  }
  &__title {
    width: 100%;
    text-align: center;
    color: #ffffff;
    font-weight: 500;
    font-size: 24px;
    line-height: 189.5%;
    margin-bottom: 25px;
  }
  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    // height: 600px;
    max-width: 900px;
    // max-height: 600px;
  }
  &__text {
    color: #ffffff;
    font-family: $primary-ff;
    font-weight: 500;
    font-size: 18px;
    line-height: 179%;
    text-align: justify;
    max-width: 476px;
  }
  &__text:not(:last-child) {
    margin-bottom: 25px;
  }
  &__img {
    // transform: rotateY(180deg);
    width: 40%;
    height: 95%;
  }
}
@media (max-width: 970px) {
  .water-info {
    &__content {
      position: relative;
    }
    &__text-block {
    }
    &__img {
      display: none;
    }
  }
}
@media (max-width: 690px) {
  .water-info {
    &__title {
      font-size: 22px;
    }
  }
}