@import "mixin";

@include font-face('DrukCyr', 'DrukCyr-Bold', 600);
@include font-face('DrukCyr', 'DrukCyr-Medium', 500);
@include font-face('DrukWide', 'Druk-Wide-Cyr-Bold', 600);
@include font-face('DrukWide', 'Druk-Wide-Cyr-Medium', 500);
@include font-face('Rubik', 'Rubik-Bold', 600);
@include font-face('Rubik', 'Rubik-Medium', 500);
@include font-face('Rubik', 'Rubik-Regular', 400);
@include font-face('Rubik', 'Rubik-Light', 300);
