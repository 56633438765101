.delivery-info {
  padding: 0 15px;
  &__container {
    margin-bottom: 75px;
  }
  &__title {
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 24px;
    line-height: 189.5%;
    margin-bottom: 25px;
  }
  &__items {
  }
  &__item {
    position: relative;
    background: #FBF3EA;
    box-shadow: 6px 4px 19px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    padding: 15px 35px;
    // overflow: hidden;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    &:hover {
      .delivery-info__item-icon {
        transition: all .3s;
        transform: rotate(90deg);
      }
    }
    &-visible { 
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      z-index: 20;
      // background: #FFFFFF;
      // padding: 15px 35px;
      // border-radius: 50px;
      
    }
    &-dropdown {
      display: flex;
      flex-direction: column;
      gap: 20px;
      transition: all .3s;
      visibility: hidden;
      opacity: 0;
      margin-top: 20px;
      width: 100%;
      left: 0;
      top: -80%;
      z-index: -10;
      position: absolute;
      padding: 20px 35px;
      box-shadow: 3px 14px 19px rgba(0, 0, 0, 0.25);
      background: #FBF3EA;
      border-radius: 50px;
      &._active {
        top: 90%;
        z-index: 20;
        visibility: visible;
        opacity: 1;
      }
      &-block {
      }
      &-title.title_h5{
        font-weight: 500;
        font-size: 16px;
        line-height: 200%;
      }
      &-title.title_h6{
        font-weight: 500;
        font-size: 14px;
        line-height: 200%;
      }
      &-links {
        display: flex;
        align-items: center;
        gap: 5%;
      }
      &-link {
        font-weight: 500;
        font-size: 14px;
        line-height: 200%;
        color: #12B6FF;
        &_premium {
          max-width: 270px;
          padding: 10px;
          border: 1px solid #12B6FF;
        }
      }
      &-text {
        color: #C7836F;
        font-weight: 500;
        font-size: 14px;
        line-height: 125%;
      }
    }
    
  }
  &__item-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 200%;
  }
  &__item-icon:hover {
    transform: rotate(90deg);
  }
}

@media (max-width: 690px) {
  .delivery-info {
    &__title {
      font-size: 22px;
    }
  }
}
@media (max-width: 780px) {
  .delivery-info {
    &__item-title {
      font-size: 15px;
    }
  }
}
@media (max-width: 630px) {
  .delivery-info {
    
  }
}
@media (max-width: 550px) {
  .delivery-info {
    &__item {
      padding: 15px 25px;
      &-dropdown {
        &-links {
          align-items: start;
          flex-direction: column;
        }
      }
    }
    &__item-title {
      font-size: 14px;
    }
  }
}
