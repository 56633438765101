* {
  // user-select: none;
  font-family: $base-ff;
  font-size: $base-fsz;
  font-weight: $base-fw;
  color: $base-c;
}
.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  width: 100vw;
  height: 100vh; 
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all .5s;
  z-index: 400;
  &::before {
    content: '';
    position: absolute;
    background: rgba(0, 0, 0, .25);
    width: 100%;
    height: 100%;
    z-index: 400;
  }
  &._active {
    visibility: visible;
    opacity: 1;
  }
  &._active &__apps-block {
    top: 50%;
  }
  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  &__apps-block {
    position: absolute;
    top: -100%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    z-index: 500;
    padding: 20px;
    border-radius: 20px;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  &__title {
    text-align: center;
  }
  &__apps-image {
    max-width: 150px;
    height: auto;
  }
}
.noscroll {
  overflow: hidden;
}
.container {
    max-width:  $base-w-container;
    margin: 0 auto;
}
.color {
  &_link {
    color: $link-c !important;
  }
  &_assign {
    color: $assign-c !important;
  }
  &_assign2 {
    color: #00364E !important;
  }
}
.title {
  &_h2 {
    // font-size: 24px !important;
  }
}
.wrapper {
    width: 100%;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    // background: url('../../img/bg-body.jpg') no-repeat center;
    // background-size: cover;
}
.thanks-popup {
  visibility: hidden;
  position: fixed;
  z-index: 400;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all .4s;
  &.active {
    visibility: visible;
    transform: translate(-50%, -50%);
    z-index: 200;
    opacity: 1;
  }
  &::before {
    content: '';
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: #000;
    opacity: 0.3;
    top: 50%;
    z-index: 300;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__box {
    padding: 50px;
    background: #fff;
    border-radius: 10px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 450;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    gap: 15px;
  }
  &__title {
    font-size: 36px;
  }
  &__description {
    font-size: 20px;
  }
  &__close {
    position: absolute;
    margin: 15px;
    background: #fff;
    width: 25px;
    height: 25px;
    top: 0;
    right: 0;
  }
  &__close::after,
  &__close::before {
    content: '';
    position: absolute;
    width: 25px;
    height: 2px;
    background: $base-c;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &__close::after {
    transform: translate(-50%, -50%)  rotate(45deg);
  }
  &__close::before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

}

@media (max-width:750px) {
  .thanks-popup {
    &__title {font-size: 22px;}
    &__description {font-size: 16px;}
  }
}
 