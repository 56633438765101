.submit-form {
  &__container {
    width: 476px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }
  &__info-interval {
    display: flex;
    flex-direction: column;
    text-align: left !important;
  }
  &__text-info {
    margin-top: 10px;
    font-family: $primary-ff  ;
    font-weight: 700;
    font-size: 18px;
    line-height: 140%;
  }
  &__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    &_info {
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
    }
  }
  &__input {
    background: #FBF3EA;
    border: 1px solid #00364E;
    border-radius: 30px;
    padding: 21px 25px;
    width: 100%;
    &::placeholder {
      color: rgba(0, 54, 78, 0.25);
    } 
    &:first-child {
      margin-bottom: 20px;
    }
    &._error {
      transition: all .3s;
      box-shadow: 0px 0 3px #C63131;
    }
  }
  &__btn {
    box-shadow: none;
    &-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  &__social-links {
    display: flex;
    align-items: center;
  }
  &__social-link:not(:last-child) {
    margin-right: 5px;
  }
  &__img {
    width: 43px;
    height: 43px;
    &_small {
      width: 35px !important;
      height: 35px !important;
    }
    &_big {
      width: 45px;
      height: 45px;
    }
  }
  &__policy {
    &-block {}
    &-text {
      font-family: $primary-ff !important;
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
      &_underline {
        text-decoration: underline;
      }
    }
  }
}
.confiditional {
  z-index: 1000;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, .4);
  opacity: 0;
  display: none;
  visibility: hidden;
  transition: all .3s;
  margin: 0;
  &__container {
    position: fixed;
    width: 90%;
    height: auto;
    left: 50%;
    top: 50%;
    max-width: 700px;
    max-height: 550px;
    transform: translate(-50%, -50%);
    padding: 5px 25px 15px 25px;
    border-radius: 20px;
    box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.25);
    background: #fff;
  }
  &__text { 
    font-family: 'Georgia', 'Times New Roman', Times, serif;
    font-weight: 400;
    font-size: 16px;
    z-index: 1000;
  } 
  &__li {
    font-family: 'Georgia', 'Times New Roman', Times, serif;
    font-weight: 400;
    font-size: 16px;
  }
  &__link {
    color: #47bcb6;
    font-family: 'Georgia', 'Times New Roman', Times, serif;
    font-weight: 400;
    font-size: 16px;
  }
  &__close {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px 10px 10px;
  }
  &__close_button {
    border: 1px solid rgba(0, 0, 0, .3);
    border-radius: 50%;
    position: relative;
    width: 30px;
    height: 30px;
    background: none;
  }
  &__close_button::after,
  &__close_button::before {
    content: '';
    position: absolute;
    background: rgba(0, 0, 0, .8);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 15px;
    height: 2px;
  }
  &__close_button::after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &__close_button::before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}
@media (max-width:540px) {
  .confiditional {
    &__container {
      overflow: scroll;
    }
  }
}