.about-us {
  padding: 0 15px;
  &__container {
    margin-bottom: 80px;
  }
  &__title {
    width: 100%;
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 40px;
  }
  &__plus {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 65px;
      &-items {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        column-gap: 110px;
        row-gap: 70px;
      // display: grid;
      // grid-template-columns: 1fr 1fr;
      // grid-column-gap: 110px;
      // grid-row-gap: 50px;
      // width: 770px;
      // margin: 0 auto;
    }
    &-item {
      display: flex;
      gap: 15px;
      max-width: 340px;
      flex-grow: 2;
    }
    &-img {
      max-height: 72px;
    }
    &-text-block {
      max-width: 270px;
    }
    &-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 6px;
    }
    &-text {
      font-family: 'Rubik';
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;
    }
  }
  &__comments {
    &-title {
      width: 100%;
      text-align: center;
      margin-bottom: 30px;
    }
    &-items {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 25px;
    }
    &-item {
      cursor: pointer;
      width: 250px;
      height: 250px;
      perspective: 1000px;
      &:hover {
        .about-us__comments-item-front {
          transform: rotateY(180deg);
          backface-visibility: hidden;
          z-index: -10;
        }
        .about-us__comments-item-back {
          transform: rotateY(360deg);
          z-index: 30;
        }
      }
      &-front, &-back {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        transition: all .5s;
        padding: 15px;
        background: #FBF3EA;
        border: 1px solid #00364E;
        border-radius: 20px;
      }
      &-front {
        position: relative;
        z-index: 30;
      }
      &-back {
        position: absolute;
        top: 0;
        left: 0;
        transform: rotateY(180deg);
        backface-visibility: hidden;
        z-index: -10;
      }
    }
    &-text {
      font-family: 'Rubik';
      font-weight: 300;
      font-size: 18px;
      line-height: 14px;
    }
    &-front-bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-back-top {
      .about-us__comments-text {
        font-size: 18px;
      }
    }
    &-icon {
      width: 23px;
      height: 23px;
    }
    &-front-title, &-back-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 15px;
      text-align: right;
    }
    &-back-title {
      font-size: 15px;
    }
  }
}
@media (max-width: 1120px) {
 
}
@media (max-width: 690px) {
  .about-us {
    &__title {
      font-size: 22px;
    }
  }
}