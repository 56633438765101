.contacts {
  padding: 0 15px;
  &__container {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 50px;
    margin-bottom: 84px;
  }
  &__title {
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
  }
  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 80px;
  }
  &__items {
  }
  &__item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 300px;
    margin-bottom: 10px;
    &-img {
      width: 36px;
      margin-right: 15px;
    }
    &-text {
      &-block {
        display: flex;
        row-gap: 5px;
        flex-direction: column;
        max-width: 230px;
      }
      font-weight: 500;
      font-size: 20px;
      line-height: 21px;
      font-family: $primary-ff !important;
    }
  }
  &__submit-form {}
}
@media (max-width: 1050px) {
  .contacts {
    &__content {
      // align-items: start;
      flex-direction: column;
    }
  }
}
@media (max-width: 690px) {
  .contacts {
    &__container { 
    }
    &__title {
      font-size: 22px;
    }
    &__content {
      // align-items: start;
      flex-direction: column;
    }
  }
}
@media (max-width: 550px) {
  .contacts {
    &__submit-form {
      .submit-form__title {
        font-size: 16px;
      }
      .submit-form__container {
        max-width: 80vw;
      }
      .submit-form__btn-block {
        flex-direction: column;
        gap: 15px;
      }
      .submit-form__social-links {
        width: 100%;
        justify-content: space-between;
      }
      .btn-send {
        width: 100%;
        padding: 17px 25px;
      }
    }
    &__title {
      font-size: 22px;
    }
  }
}
@media (max-width: 370px) {
  .contacts {
    &__item {
      &-text {
        font-size: 18px;
      }
    }
  }
}

