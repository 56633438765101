.map {
  margin-bottom: 85px;
  background: #3EAFC7;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  &__container {
    padding: 50px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  &__frame {
    width: 90%;
    height: 90%;
  }
}