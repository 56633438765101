.company {
  padding: 0 15px; 
  &__container {
    max-width: 1052px;
    margin-bottom: 80px;
  }
  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 189.5%;
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }
  &__items {
  }
  &__item-top,&__item-bottom  {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__item-top {
    margin-bottom: 15px;
    gap: 60px;
  }
  &__item-top-image {
    border: 1px solid #000000;
    box-shadow: 7px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    max-width: 300px;
    max-height: 420px;
  }
  &__item-top-text {
    max-width: 564px;
  }
  &__item-bottom-text {
    max-width: 589px;
  }
  &__item-top-text, &__item-bottom-text {
    font-weight: 500;
    font-size: 18px;
    line-height: 200%;
    text-align: justify;
  }
  &__item-bottom {

  }
  &__item-bottom-image {
    border-radius: 123.5px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
}
@media (max-width: 800px) {
  .company {
    &__item-top-text, &__item-bottom-text {
      font-size: 14px;
    }
    &__item-bottom-image {
      width: 273px;
    }
  }
}
@media (max-width: 600px) {
  .company {
    &__item-top  {
      flex-direction: column-reverse;
      gap: 20px;
    }
    &__item-top-text, &__item-bottom-text {
      font-size: 12px;
    }
    &__item-bottom-image {
      display: none;
    }
  }
}
@media (max-width: 690px) {
  .company {
    &__title {
      font-size: 22px;
    }
  }
}

