.header {
  padding: 0 15px;
  background-color: #3EAFC7;
  height: 100vh;
  min-height: 600px;
  margin-bottom: 85px;
  position: relative;
  &__container {
    background: url('../img/header-battle.png') no-repeat right bottom 25%;
    background-size: 608px 515px;
    padding: 35px 0px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__top {}
  &__main {}
  &__bottom {}
  &__submit-form {
    position: fixed;
    display: flex;
    visibility: hidden;
    top: -50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 50px !important;
    border-radius: 20px;
    transition: all .5s;
    z-index: 200;
    &-wrapper {
      position: fixed;
      top: 50%;
      left: 50%;
      width: 100vw;
      height: 100vh;
      z-index: 200;
      transform: translate(-50%, -50%);
      visibility: hidden;
      opacity: 0;
      transition: all .4s;
      background: rgba(0, 0, 0, .25);
      z-index: 100;
      // &::before {
      //   content: '';
      //   position: absolute;
      //   width: 100%;
      //   top: 50%;
      //   left: 50%;
      //   transform: translate(-50%, -50%);
      //   height: 100%;
        
      // }
      &.active {
        opacity: 1;
        visibility: visible;
      }
    }
    &.active {
      top: 50%;
      visibility: visible;
    }
  }
}
.header-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__logo {
    &-block {
    }
    &-link {
      display: flex;
      align-items: center;
      text-align-last: left;
      column-gap: 10px;
    }
    &-img {
      width: 50px;
      height: 50px;
    }
    &-text-block {}
    &-name {
      margin-bottom: 12px;
      font-weight: 700;
      font-size: 18px;
      line-height: 100%;
      color: #FBF3EA;
    }
    &-number {
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      color: #FBF3EA;
    }
  }
  &__nav {}
  &__message {
    &-link {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    &-text-block {
      max-width: 145px;
    }
    &-text {
      font-weight: 600;
      font-size: 16px;
      color: #FBF3EA;
      text-align: right;
    }
  }
}
.social {
  display: none;
  &__container {
    display: flex;
    align-items: center;
  }
  &__img {
    width: 45px;
    height: auto;
    margin-right: 10px;
    &_big {
      width: 50px;
    }
    &_small {
      width: 40px;
      height: auto;
    }
  }
}
.nav-contacts {
  &__items {
    display: none;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 15px;
    max-width: 300px;
  }
  &__item {
    display: flex;
    align-items: center;
    gap: 10px;
    &-text-block {
      display: flex;
      flex-direction: column;
    }
    &-text {
      font-size: 18px;
      font-weight: 400;
      font-family:  $primary-ff;
      color: #FBF3EA;
    }
    &-img {
      width: 30px;
      height: auto;
      display: none;
      fill: #FBF3EA;
    }
  }
  
}
.nav {
  .nav__item:first-child {
    .nav__link {
      font-weight: 600;
    }
  }
  &__menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 25px;
  }
  &__item {

  }
  &__link {
    font-size: 18px;
    font-family: $primary-ff;
    font-weight: 400;
    color: #FBF3EA;
    scroll-behavior: smooth;
    
  }
  &__link:hover {
    text-decoration: underline;
  }
  &__social-link {

  }
  &__message {
    max-width: 300px;
    display: none;
    flex-direction: column;
  }
}
.burger {
  width: 30px;
  height: 25px;
  position: relative;
  background: none;
  display: none;
  transition: all .3s;
  z-index: 90;
  &::before, &::after, &__line {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: #fff;
    transition: all .3s;
  }
  &::after {
    top: 50%;
  }
  &__line {
    top: 100%;
  }
  &._active {
    position: fixed;
    top: 47px;
    right: 15px;
  }
  &._active::before {
    top: 50%;
    transform: translate(0, -50%) rotate(45deg);
  }
  &._active::after {
    transform: translate(0, -50%) rotate(-45deg);
  }
  &._active &__line {
    display: none;
  }
}
.header-main {
  max-width: 626px;
  &__app-block {
    margin-top: 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
  }
  &__btn {
    color: #FBF3EA !important;
  }
  &__text {
    margin-bottom: 50px;
  }
  &__title {
    color: #FBF3EA;
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
    margin-bottom: 10px;
  }
  &__description {
    color: #FBF3EA;
    font-family: $primary-ff;
    font-weight: 400;
    font-size: 18px;
    line-height: 145%;
  }
  &__send {
    &-block {
      display: flex;
      flex-direction: column;
      gap: 26px;
    }
    &-top {
      display: flex;
      align-items: center;
      column-gap: 25px;
    }
    &-number {
      color: #FBF3EA;
      font-weight: 500;
      font-size: 28px;
      line-height: 145%;
    }
    &-bottom {
      margin: 0;
    }
    &-text {
      font-family: 'Rubik';
      font-weight: 400;
      font-size: 16px;
      line-height: 145%;
      color: #FBF3EA;
    }
  }
}
.btn-send {
  color: #ffffff;
  background: #00364E;
  // box-shadow: 0px 0px 73px #00364E;
  border-radius: 30px;
  padding: 17px 53px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
}
.header-bottom {
  width: 100%;
  padding: 0 50%;
  &__btn {
    background: none;
  }
}
.whatsapp-popup {
  width: 100vw;
  height: 100vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  z-index: 200; 
  visibility: hidden;
  opacity: 0;
  transition: all .4s;
  &._active {
    visibility: visible;
    opacity: 1;
  }
  &::before {
    content: '';
    position: absolute;
    background: rgba(0, 0, 0, .25);
    width: 100%;
    height: 100%;
    z-index: 100;
  }
  &__close {
    position: absolute;
    top: 0;
    right: 0;
    margin: 12px;
  }
  &__block {
    background: #ffffff;
    border-radius: 20px;
    padding: 27px;
    position: fixed;
    top: -50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 200;
    transition: all .7s;
    &._active {
      top: 50%;
    }
  }
  &__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
  }
}
@media (max-width: 1130px) {
  .header {
    &__container {
      background-size: 568px 485px;
    }
  }
}

@media (max-width: 1000px) {
  .header {
    position: relative;
  }
  .header-top {
    &__message-block {
      display: none;
    }
    &__logo {
      &-block {
      }
      &-link {
        display: flex;
        align-items: center;
        text-align-last: left;
        column-gap: 10px;
      }
      &-img {
      }
      &-text-block {}
      &-name {
        margin-bottom: 5px;
      }
      &-number {
      }
    }
  }
  .header-main {
    &__text {
      margin-bottom: 30px;
    }
    &__send {
      &-bottom {
      }
      &-text {
        // display: none;
        font-size: 14px;
      }
    }
  }
  .nav {
    position: fixed;
    top: 0;
    left: -73%;
    width: 73%;
    height: 100vh;
    padding: 40px;
    background: #00364E;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    gap: 45px;
    transition: all .4s;
    z-index: 200;
    &._active {
      left: 0;
    }
    &__menu {
      align-items: flex-start;
      gap: 25px;
      flex-direction: column;
    }
    &__message {
      display: flex;
      gap: 10px;
      margin: 0;
      .header-top__message-link {
        width: 100%;
      }
      .header-top__message-text-block {
        max-width: max-content;
      }
      .header-top__message-text {
        text-align: left;
      }
    }
  }
  .nav-contacts {
    &__items {
      display: flex;
    }
  }
  .social {
    display: flex;
  }
  .burger {
    display: block;
  }
}
@media (max-width: 850px) {
  .header-main {
    &__title {
      font-size: 20px;
    }
    &__description {
      font-size: 16px;
    }
  }
}

@media (max-width:680px) {
  .header {
    &__submit-form {
      padding: 30px !important;
      &-wrapper {
      padding: 0 30px;
      }
      .submit-form__title {
        font-size: 16px;
      }
      .submit-form__container {
        max-width: 80vw;
      }
      .submit-form__btn-block {
        flex-direction: column;
        gap: 15px;
      }
      .submit-form__social-links {
        width: 100%;
        justify-content: space-between;
      }
      .btn-send {
        width: 100%;
        padding: 17px 25px;
      }
    }
    &__title {
      font-size: 22px;
    }
  }
}
@media (max-width: 550px) {
  .header {
    &__container {
      background: none;
      // background: url('../img/h-battle-desktop.png') no-repeat center bottom;
      background-size: 400px auto;
    }
  }
  .header-top {
    &__logo {
      &-link {
        display: flex;
        align-items: center;
        text-align-last: left;
        column-gap: 10px;
      }
      &-img {
        width: 45px;
        height: auto;
      }
      &-text-block {}
      &-name {
        font-size: 16px;
      }
      &-number {
      }
    }
  }
  .header-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // text-align: center;

    &__app-block {
      display: none;
      justify-content: center;
    }
    &__title {
      font-size: 16px;
    }
    &__description {
      font-size: 14px;
    }
    &__btn {
      padding: 15px 30px !important;
      font-size: 12px;
    }
    &__send {
      &-number {
        font-size: 22px;
      }
      &-block {
        gap: 10px;
      }
      &-top {
        gap: 15px;
        flex-wrap: wrap;
      }
    }
  }
  .nav {
    overflow-x: scroll;
    padding: 20px;
    &__message {
      display: flex;
      margin: 0;
      .header-top__message-text {
        font-size: 15px;
      }
    }
  }
}
@media (max-width: 380px) {
  .nav {
    padding: 20px;
    &__message {
      display: flex;
      margin: 0;
      .header-top__message-text {
        font-size: 14px;
      }
      .header-top__message-link {
        align-items: flex-start;
        flex-direction: column;
      }
      .header-top__logo-img {
        width: 40px;
        height: 40px;
        display: none;
      }
    }
  }
  .header-main {
    &__send {
      &-block {
        gap: 10px;
      }
      &-top {
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
@media (max-width: 460px) {
  .header {
    &__container {
      background-size: 360px auto;
    }
    &__submit-form {
      padding: 20px !important;
      &-wrapper {
        padding: 0 15px;
      }
    }
  }
  .whatsapp-popup {
    &__block {
      padding: 15px;
    }
    &__img {
      width: 300px;
      height: auto;
    }
    &__title {
      font-weight: 500;
      font-size: 18px;
      line-height: 29px;
    }
  }
}
@media (max-height: 580px) {
  .header {
    &__submit-form {
      &.active {
        top: 80%;
        visibility: visible;
      }
      &-wrapper {
        overflow: scroll;
      }
    }
  }
  
}
@media (max-width: 410px) {
  .header {
    &__container {
      // background: url('../img/h-battle-desktop.png') no-repeat center bottom;
      background-size: 300px auto;
    }
  }
}
@media (max-width: 370px) {
  .header {
    &__container {
      // background: url('../img/h-battle-desktop.png') no-repeat center bottom;
      background-size: 270px auto;
    }
  }
  .whatsapp-popup {
    &__block {
      padding: 15px;
    }
    &__img {
      width: 250px;
      height: auto;
    }
    &__title {
      font-weight: 500;
      font-size: 18px;
      line-height: 29px;
    }
  }
}
